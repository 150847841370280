<script>
export default {
    name: "Logout",
    beforeCreate(){
        this.$nextTick(() =>{
            this.$store.dispatch("logout");
            this.$router.push({name: 'Home'})
        })
    }   
}
</script>